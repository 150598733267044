<template>
	<v-layout column fill-height>
		<v-flex shrink>
			<WButton :disabled="treeStructure.is_default_for_accounting_firm || !treeStructure.id" @click="setTreeStructureAsDefaultForAccountingFirm">
				{{ $t('ecm-catalog.set_as_accounting_firms_ged') }}
			</WButton>
			<WButton v-if="treeStructure.has_access" :disabled="!treeStructure.id || treeStructure.is_default_for_customers" icon="remove" @click="removeFromList">{{
				$t('ecm-catalog.remove_from_list')
			}}</WButton>
			<WButton v-else :disabled="!treeStructure.id" icon="add" @click="addToList">{{ $t('ecm-catalog.add_to_list') }}</WButton>
		</v-flex>
		<v-flex scroll-y>
			<v-layout column fill-height>
				<v-flex :scroll-y="$vuetify.breakpoint.lgAndUp">
					<WSection
						:fill-height="$vuetify.breakpoint.lgAndUp"
						no-margin
						sticky
						:title="$t('ecm-catalog.tree_structure_model', { name: treeStructure.name }) + ' :'"
					>
						<v-layout fill-height>
							<v-flex v-if="treeStructure.tree_structure" pl-3 py-3 scroll-y>
								<v-treeview :items="treeStructure.tree_structure" item-children="treeview" open-all />
							</v-flex>
						</v-layout>
					</WSection>
				</v-flex>
				<v-flex shrink>
					<WSection v-if="treeStructure.themes && treeStructure.themes.length > 0" :title="$t('ecm-catalog.used_themes') + ' :'">
						<v-layout row wrap>
							<v-chip v-for="theme in treeStructure.themes" :key="theme.id" :color="theme.color" small>{{ theme.title }}</v-chip>
						</v-layout>
					</WSection>
				</v-flex>
			</v-layout>
		</v-flex>
	</v-layout>
</template>

<script>
import ECMCatalogModuleGuard from '@/mixins/ModulesGuards/ECMCatalog/ECMCatalogModuleGuard'

export default {
	name: 'ECMCatalogForm',
	mixins: [ECMCatalogModuleGuard],
	props: {
		value: {
			required: true,
			type: Number
		}
	},
	data: function () {
		return {
			treeStructure: {}
		}
	},
	watch: {
		value: {
			handler: function (val) {
				if (val) {
					this.getTreeStructure()
				}
			},
			immediate: true
		}
	},
	methods: {
		getModuleEventsActionsMapping: function () {
			return [
				{ event: this.events.TREE_STRUCTURE_ADDED, action: this.onTreeStructureAddedToList },
				{ event: this.events.TREE_STRUCTURE_REMOVED, action: this.onTreeStructureRemovedFromList }
			]
		},
		addToList: function () {
			return this.service.addTreeStructureToList(this.treeStructure).then(() => {
				this.treeStructure.has_access = true
			})
		},
		getTreeStructure: function () {
			this.loading = true
			this.treeStructure = {}
			const id = this.value
			return this.service
				.getTreeStructure(id)
				.then(treeStructure => {
					this.treeStructure = treeStructure
				})
				.finally(() => {
					this.loading = false
				})
		},
		onTreeStructureAddedToList: function (treeStructure) {
			if (treeStructure.id === this.treeStructure.id) {
				this.treeStructure.has_access = true
			}
		},
		onTreeStructureRemovedFromList: function (treeStructure) {
			if (treeStructure.id === this.treeStructure.id) {
				this.treeStructure.has_access = false
			}
		},
		removeFromList: function () {
			return this.service.removeTreeStructureFromList(this.treeStructure).then(() => {
				this.treeStructure.has_access = false
			})
		},
		setTreeStructureAsDefaultForAccountingFirm: function () {
			const treeStructure = this.treeStructure
			return this.service.setTreeStructureAsDefaultForAccountingFirm(treeStructure).then(() => {
				this.treeStructure.is_default_for_accounting_firm = true
			})
		}
	}
}
</script>
